import { createSlice } from '@reduxjs/toolkit';

interface MenuState {
  visible: boolean;
}

const initialState: MenuState = {
  visible: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggle(state) {
      state.visible = !state.visible;
    },
  },
});

export const menuActions = menuSlice.actions;
export default menuSlice.reducer;
