import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Game } from 'interfaces';
import { RootState } from '.';

interface GameState {
  selectedId: string | null;
  list: Game[];
}

const storedState: GameState | null = JSON.parse('' + localStorage.getItem('games'));
const gameExists = (list: Game[], id: string | null) => list.find((game) => game.id === id);

const initialState: GameState = storedState || {
  selectedId: null,
  list: [],
};

const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setGames(state, action: PayloadAction<Game[]>) {
      state.list = action.payload;
      if (action.payload.length && !gameExists(action.payload, state.selectedId)) {
        state.selectedId = action.payload[0].id;
      }
    },
    add(state, action: PayloadAction<Game>) {
      state.list.push(action.payload);
    },
    select(state, action: PayloadAction<string>) {
      state.selectedId = action.payload;
    },
    setGameIcon(state, action: PayloadAction<{ id: string; icon: string }>) {
      const game = state.list.find((game) => game.id === action.payload.id);
      if (game) {
        game.icon = action.payload.icon;
      }
    },
  },
});

export const selectedGameSelector = (state: RootState) =>
  gameExists(state.games.list, state.games.selectedId);

export const unselectedGamesSelector = (state: RootState) =>
  state.games.list.filter((game) => game.id !== state.games.selectedId);

export const gamesActions = gamesSlice.actions;
export default gamesSlice.reducer;
