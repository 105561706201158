import { FC } from 'react';
import useSlice from 'hooks/useSlice';
import Notification from 'components/Notification/Notification';
import { Notification as NotificationType } from 'interfaces';
import ClickableListItem from 'components/ClickableListItem/ClickableListItem';

interface Props {
  onSelect?: () => void;
}

const NotificationList: FC<Props> = ({ onSelect = () => null }) => {
  const notifications = useSlice('notifications');

  return (
    <>
      <ul className="unstyled-list">
        {notifications.slice(0, 5).map((notification: NotificationType) => (
          <ClickableListItem key={notification.id} onClick={onSelect}>
            <Notification
              id={notification.id}
              title={notification.title}
              date={notification.date}
              content={notification.text}
              seen={notification.seen}
              icon={notification.icon}
            />
          </ClickableListItem>
        ))}
      </ul>
    </>
  );
};

export default NotificationList;
