import { FC } from 'react';
import AccountActionList from 'components/AccountActionList/AccountActionList';
import GamesWidget from '../GamesWidget/GamesWidget';
import classes from './AccountDropDown.module.scss';

interface Props {
  onClose: () => void;
}

const AccountDropDown: FC<Props> = ({ onClose = () => null }) => {
  return (
    <div className={classes.container}>
      <GamesWidget onClose={onClose} />
      <AccountActionList className="mv3" />
    </div>
  );
};

export default AccountDropDown;
