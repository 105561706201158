import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLocale } from 'utils/user-locale';

type LangState = {
  code: string;
};

const defaultState: LangState = {
  code: getLocale(),
};

const storedState = localStorage.getItem('lang');
const initialState: LangState = storedState !== null ? { code: storedState } : defaultState;

const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    set(state, action: PayloadAction<string>) {
      state.code = action.payload;
    },
  },
});

// const setLang = (lang: string) => async (dispatch: (action: any) => void) => {
//   dispatch(langSlice.actions.setLang(lang));
// };
// export const langActions = {
//   setLang: langSlice.actions.setLang,
// };
export const langActions = langSlice.actions;
export default langSlice.reducer;
