import { FC, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectedGameSelector } from 'store/games';
import { FaBell, FaUserCircle } from 'react-icons/fa';
import classes from './HeaderActions.module.scss';
import { DropDown } from '@marsenit/gameout-ui';
import HeaderAction from 'components/HeaderAction/HeaderAction';
import AccountDropDown from 'components/AccountDropDown/AccountDropDown';
import GameIcon from '../GameIcon/GameIcon';
import NotificationsDropDown from 'components/NotificationsDropDown/NotificationsDropDown';
import { LanguageFlag, LanguageList } from '@marsenit/gameout-ui/';
import useSlice from 'hooks/useSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { langActions } from 'store/lang';

enum DROPDOWN {
  LANGUAGE,
  NOTIFICATIONS,
  ACCOUNT,
}

interface Props {
  unseenNotifications: number;
}

interface DropDownState {
  target: Element;
  content: DROPDOWN;
}

const HeaderActions: FC<Props> = ({ unseenNotifications }) => {
  const [dropDownState, setDropDownState] = useState<DropDownState | null>(null);
  const selectedGame = useSelector(selectedGameSelector)!;
  const { code: language } = useSlice('lang');
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const icon = selectedGame?.icon;
  const isDropDownVisible = dropDownState !== null;

  const iconClickHandler = (e: MouseEvent, content: DROPDOWN) => {
    e.stopPropagation();

    setDropDownState({ target: e.currentTarget, content });
  };

  const onCloseHandler = () => setDropDownState(null);

  const onLanguageSelected = (code?: string) => {
    onCloseHandler();
    if (code) {
      dispatch(langActions.set(code));
      i18n.changeLanguage(code);
    }
  };

  const dropDownContent = () => {
    switch (dropDownState?.content) {
      case DROPDOWN.ACCOUNT:
        return <AccountDropDown onClose={onCloseHandler} />;
      case DROPDOWN.LANGUAGE:
        return <LanguageList onSelect={onLanguageSelected} />;
      case DROPDOWN.NOTIFICATIONS:
        return <NotificationsDropDown onSelect={onCloseHandler} />;
      default:
        return null;
    }
  };

  return (
    <>
      <ul className={classes.actions}>
        <HeaderAction onClick={(e) => iconClickHandler(e, DROPDOWN.LANGUAGE)}>
          {language && <LanguageFlag name={language} className={classes['lang-icon']} />}
        </HeaderAction>
        <HeaderAction
          hasNewContent={unseenNotifications > 0}
          onClick={(e) => iconClickHandler(e, DROPDOWN.NOTIFICATIONS)}>
          <FaBell className={classes['action-active']} />
        </HeaderAction>
        <HeaderAction onClick={(e) => iconClickHandler(e, DROPDOWN.ACCOUNT)}>
          {icon ? (
            <GameIcon name={icon} className={classes['game-icon']} />
          ) : (
            <FaUserCircle className={classes['no-game-icon']} />
          )}
        </HeaderAction>
      </ul>
      {isDropDownVisible && (
        <DropDown target={dropDownState.target} onClose={onCloseHandler}>
          {dropDownContent()}
        </DropDown>
      )}
    </>
  );
};

export default HeaderActions;
