import { Component, ElementType, ReactNode } from 'react';

interface Props {
  fallback: ElementType;
  children: ReactNode;
}

export default class ErrorBoundary extends Component<Props> {
  state = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { children, fallback } = this.props;
    const Fallback: ElementType = fallback;

    if (error) return <Fallback error={error} />;
    return children;
  }
}
