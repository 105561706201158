import { FC, useState } from 'react';
import classes from './GamesWidget.module.scss';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { selectedGameSelector } from 'store/games';
import GameIcon from '../GameIcon/GameIcon';
import Icon from 'components/UI/Icon/Icon';
import { Modal } from '@marsenit/gameout-ui';
import GameSelectorModal from 'pages/modals/GameSelectorModal/GameSelectorModal';

interface Props {
  mobile?: boolean;
  onClose?: () => void;
}

const GamesWidget: FC<Props> = ({ mobile, onClose = () => null }) => {
  const [isGameSelectorVisible, setGameSelectorVisible] = useState(false);
  const selectedGame = useSelector(selectedGameSelector)!;
  const { name, icon } = selectedGame || {};

  const showGameSelector = () => setGameSelectorVisible(true);

  const closeGameSelector = () => {
    onClose();
    setGameSelectorVisible(false);
  };

  const containerClasses = classNames(classes['game-container'], {
    [classes['game-container--padding-top']]: selectedGame,
    [classes['game-container--mobile']]: mobile,
  });

  const gameIconClasses = classNames({
    [classes['game-icon--mobile']]: mobile,
  });

  return (
    <>
      <div className={containerClasses} onClick={showGameSelector}>
        {icon && (
          <GameIcon
            name={icon}
            size={mobile ? 'small' : 'medium'}
            className={gameIconClasses}
            onClick={showGameSelector}
            highlightOnHover
          />
        )}
        {name && <div className={classes['game-name']}>{name}</div>}
        {mobile && <Icon name="FaRetweet" />}
      </div>
      {isGameSelectorVisible && (
        <Modal title="Select a game" onClose={closeGameSelector}>
          <GameSelectorModal onSelect={closeGameSelector} />
        </Modal>
      )}
    </>
  );
};

export default GamesWidget;
