import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from 'interfaces';
import { RootState } from '.';

const initialState: Notification[] = [
  {
    id: '1',
    title: 'Battle MU',
    text: "This is just a test notification. Let's see if this arrives just fine.",
    date: '7:02PM',
    icon: '19c25616-0abd-4c7f-b341-e2947b5bc020',
    seen: false,
  },
  {
    id: '2',
    title: 'Molten WoW',
    text: "This is a longer test notification to see how it's rendered on the browser and mobile.",
    date: 'Sept 21',
    icon: 'c7c24f3a-f082-49ed-b3f2-9c3fded1c9e8',
    seen: false,
  },
  {
    id: '3',
    title: 'Minecraft World',
    text: "This is a longer test notification to see how it's rendered on the browser and mobile.",
    date: 'July 14',
    icon: '3f6103dd-f5da-48d4-ba23-f4ade79ae559',
    seen: true,
  },
  {
    id: '4',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
  {
    id: '5',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
  {
    id: '6',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
  {
    id: '7',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
  {
    id: '8',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
  {
    id: '9',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
  {
    id: '10',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
  {
    id: '11',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
  {
    id: '12',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
  {
    id: '13',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
  {
    id: '14',
    title: 'Minecraft World',
    text: 'Your Minecraft World game membership is about to expire, renew it to prevent service disruption.',
    date: 'July 14',
    seen: true,
  },
];

const notificationsSlice = createSlice({
  name: 'developerNotifications',
  initialState,
  reducers: {
    add(state, action: PayloadAction<Notification>) {
      state.push(action.payload);
    },
  },
});

export const unseenCountSelector = (state: RootState) =>
  state.notifications.reduce((prev, curr) => (curr.seen ? prev : ++prev), 0);

export const { add } = notificationsSlice.actions;
export default notificationsSlice.reducer;
