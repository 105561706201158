import { FC, ReactNode } from 'react';
import SideMenu from 'components/SideMenu/SideMenu';
import Header from 'components/Header/Header';
import NotificationBar from 'components/NotificationBar/NotificationBar';
import useSlice from 'hooks/useSlice';
import classes from './UserLayout.module.scss';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

const UserLayout: FC<Props> = ({ children }) => {
  const { visible: isMenuVisible } = useSlice('menu');
  const { pathname } = useLocation();
  const isGameRoute = pathname.startsWith('/game/');

  return (
    <div className={classes.layout}>
      <Header className={classes['layout__header']} />
      <SideMenu
        className={`${classes['layout__menu']} ${
          !isMenuVisible ? classes['layout__menu--hidden'] : ''
        }`}
      />
      {isGameRoute && (
        <NotificationBar className={classes['layout__notibar']}>
          This game has a limit of 25,000 outbound messages. Add a payment method to remove the
          limit.
        </NotificationBar>
      )}
      <main className={classes['layout__main']}>
        <section className={classes.content}>{children}</section>
      </main>
    </div>
  );
};

export default UserLayout;
