import React, { FC } from 'react';
import classNames from 'classnames';

import classes from './Header.module.scss';
import logoPng from 'assets/images/logo.png';

import { useSelector, useDispatch } from 'react-redux';
import { menuActions } from 'store/menu';
import { unseenCountSelector } from 'store/notifications';
import HeaderActions from 'components/HeaderActions/HeaderActions';
import HeaderNewContentIndicator from 'components/HeaderNewContentIndicator/HeaderNewContentIndicator';

interface Props {
  className: string;
}

const Header: FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const mergedClasses = classNames(classes.header, className);
  const unseenNotificationsCount = useSelector(unseenCountSelector);

  const menuIconTapHandler = () => dispatch(menuActions.toggle());

  return (
    <header className={mergedClasses}>
      <img src={logoPng} alt="logo" className={classes.logo} />
      <button className={classes['menu-toggle']} onClick={menuIconTapHandler}>
        toggle menu
        {unseenNotificationsCount > 0 && <HeaderNewContentIndicator />}
      </button>
      <HeaderActions unseenNotifications={unseenNotificationsCount} />
    </header>
  );
};

export default Header;
