import { FC } from 'react';
import useSlice from 'hooks/useSlice';
import Toast from 'components/UI/Toast/Toast';
import classes from './ToastHandler.module.scss';

const ToastHandler: FC = () => {
  const { activeToasts } = useSlice('toast');
  if (!activeToasts.length) return null;

  return (
    <section className={classes.wrapper}>
      {activeToasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </section>
  );
};

export default ToastHandler;
