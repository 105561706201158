import NotificationList from 'components/NotificationList/NotificationList';
import useSlice from 'hooks/useSlice';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './NotificationsDropDown.module.scss';

interface Props {
  onSelect: () => void;
}

const NotificationsDropDown: FC<Props> = ({ onSelect = () => null }) => {
  const notifications = useSlice('notifications');
  const navigate = useNavigate();

  const seeMoreHandler = () => {
    onSelect();
    navigate('/notifications');
  };

  return (
    <div className={classes.container}>
      <NotificationList onSelect={onSelect} />
      {notifications.length > 0 && (
        <p className={classes.more} onClick={seeMoreHandler}>
          See more (11)
        </p>
      )}
    </div>
  );
};

export default NotificationsDropDown;
