import GameIcon from 'components/GameIcon/GameIcon';
import { FC } from 'react';
import classes from './Notification.module.scss';

interface Props {
  id: string;
  title: string;
  content: string;
  date: string;
  seen: boolean;
  icon?: string;
}

const Notification: FC<Props> = ({ id, title, content, date, icon, seen = false }) => {
  const titleClass = seen ? 'caps3' : 'caps2';
  const messageClass = seen ? 'unimportant' : '';

  return (
    <li className={`${classes.notification} ${seen ? classes.seen : ''}`}>
      <GameIcon size="small" name={icon} />
      <div className={`ml4 ${classes.content}`}>
        <div className={classes['header']}>
          <div className={`${classes['header__title']} ${titleClass}`}>{title}</div>
          <div className={`${classes['header__date']} small`}>{date}</div>
        </div>
        <div className={`${classes.message} ${messageClass}`}>{content}</div>
      </div>
    </li>
  );
};

export default Notification;
