import React, { FC } from 'react';
import { FaExclamationCircle, FaCheckCircle, FaTimes } from 'react-icons/fa';
import classes from './Toast.module.scss';
import { useDispatch } from 'react-redux';
import { toastActions } from 'store/toast';
import { Toast as ToastClass } from 'interfaces';

const Toast: FC<ToastClass> = ({ id, type, message }) => {
  const dispatch = useDispatch();

  const close = () => dispatch(toastActions.remove(id));

  const Icon: React.ElementType = type === 'success' ? FaCheckCircle : FaExclamationCircle;

  return (
    <div className={`${classes.toast}`}>
      <Icon className={`${classes.icon} ${classes[type]}`} />
      <p className={classes.message}>{message}</p>
      <FaTimes className={classes['close-btn']} onClick={close} />
    </div>
  );
};

export default Toast;
