import { FC, ReactNode } from 'react';
import classes from './ClickableListItem.module.scss';

interface Props {
  onClick: () => void;
  children: ReactNode;
}

const ClickableListItem: FC<Props> = ({ onClick = () => null, children }) => {
  return (
    <div className={classes['clickable-list-item']} onClick={onClick}>
      {children}
    </div>
  );
};

export default ClickableListItem;
