import { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import classes from './NotificationBar.module.scss';
import Icon from 'components/UI/Icon/Icon';

interface Props {
  className: string;
  children?: ReactNode;
}

const NotificationBar: FC<Props> = ({ className, children }) => {
  const [hidden, setHidden] = useState(false);
  const containerClasses = classNames(classes.container, className, {
    hidden: hidden,
  });

  const closeIconHandler = () => setHidden(true);

  return (
    <section className={containerClasses}>
      <p className="ml3 text-center">{children}</p>

      <div className={classes['close-icon-wrapper']} onClick={closeIconHandler}>
        <Icon name="FaTimes" />
      </div>
    </section>
  );
};

export default NotificationBar;
