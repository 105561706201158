import React, { FC, Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import useSlice from 'hooks/useSlice';
import ToastHandler from 'components/ToastHandler/ToastHandler';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import UnknownError from 'pages/Errors/UnknownError/UnknownError';
import UserLayout from 'layouts/UserLayout/UserLayout';
import GuestLayout from 'layouts/GuestLayout/GuestLayout';

const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'));
const SignIn = React.lazy(() => import('pages/SignIn/SignIn'));
const SignInVerify = React.lazy(() => import('pages/SignInVerify/SignInVerify'));
const Recover = React.lazy(() => import('pages/Recover/Recover'));
const RecoverVerify = React.lazy(() => import('pages/RecoverVerify/RecoverVerify'));
const ChangePassword = React.lazy(() => import('pages/ChangePassword/ChangePassword'));
const SignUp = React.lazy(() => import('pages/SignUp/SignUp'));
const SignUpVerify = React.lazy(() => import('pages/SignUpVerify/SignUpVerify'));
const NotFoundError = React.lazy(() => import('pages/Errors/NotFoundError/NotFoundError'));
const Game = React.lazy(() => import('pages/Game/Game'));
const Games = React.lazy(() => import('pages/Games/Games'));
const Notifications = React.lazy(() => import('pages/Notifications/Notifications'));

const Redirect: FC<{ to: string }> = ({ to }) => <Navigate replace to={`/${to}`} />;

const App = () => {
  const { isAuthenticated } = useSlice('user');

  const Guarded: FC<{ route: FC; signedOut?: boolean }> = ({ route: Element, signedOut }) => {
    if (signedOut) {
      return !isAuthenticated ? (
        <GuestLayout>
          <Element />
        </GuestLayout>
      ) : (
        <Redirect to={'dashboard'} />
      );
    } else {
      return isAuthenticated ? (
        <UserLayout>
          <Element />
        </UserLayout>
      ) : (
        <Redirect to={'login'} />
      );
    }
  };

  return (
    <ErrorBoundary fallback={UnknownError}>
      <Suspense fallback={null}>
        <Router basename={'/'}>
          <Routes>
            <Route path="/" element={<Redirect to={'dashboard'} />} />
            <Route path="dashboard" element={<Guarded route={Dashboard} />} />
            <Route path="login" element={<Guarded route={SignIn} signedOut />} />
            <Route path="login/verify" element={<Guarded route={SignInVerify} signedOut />} />
            <Route path="signup" element={<Guarded route={SignUp} signedOut />} />
            <Route path="signup/verify" element={<Guarded route={SignUpVerify} signedOut />} />
            <Route path="game/:id" element={<Guarded route={Game} />} />
            <Route path="games" element={<Guarded route={Games} />} />
            <Route path="notifications" element={<Guarded route={Notifications} />} />
            <Route path="account/recover" element={<Guarded route={Recover} signedOut />} />
            <Route
              path="account/recover/verify"
              element={<Guarded route={RecoverVerify} signedOut />}
            />
            <Route
              path="account/change-password"
              element={
                <GuestLayout>
                  <ChangePassword />
                </GuestLayout>
              }
            />
            <Route path="*" element={<NotFoundError />} />
          </Routes>
        </Router>
        <ToastHandler />
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
