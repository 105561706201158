import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Toast } from 'interfaces';
import { v4 as uuidv4 } from 'uuid';

interface ToastState {
  activeToasts: Toast[];
}

type Type = 'success' | 'error';

interface CreatePayload {
  id: string;
  type: Type;
  message: string;
}

const initialState: ToastState = {
  activeToasts: [],
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    create(state, action: PayloadAction<CreatePayload>) {
      state.activeToasts.push({
        id: action.payload.id,
        type: action.payload.type,
        message: action.payload.message,
      });
    },
    remove(state, action: PayloadAction<string>) {
      state.activeToasts = state.activeToasts.filter((toast) => toast.id !== action.payload);
    },
  },
});

const thunk =
  (message: string, type: Type, ttl?: number) => async (dispatch: (action: any) => void) => {
    const id = uuidv4();
    dispatch(toastSlice.actions.create({ id, message, type }));
    setTimeout(() => {
      dispatch(toastSlice.actions.remove(id));
    }, ttl ?? 5000);
  };

const success = (message: string, ttl?: number) => thunk(message, 'success', ttl);
const error = (message: string, ttl?: number) => thunk(message, 'error', ttl);
const remove = toastSlice.actions.remove;

export const toastActions = {
  success,
  error,
  remove,
};
export default toastSlice.reducer;
