import React, { FC, ReactNode } from 'react';
import HeaderNewContentIndicator from 'components/HeaderNewContentIndicator/HeaderNewContentIndicator';
import classes from './HeaderAction.module.scss';

interface Props {
  hasNewContent?: boolean;
  onClick: (e: React.MouseEvent) => void;
  children: ReactNode;
}

const HeaderAction: FC<Props> = ({ hasNewContent, onClick, children }) => {
  return (
    <li className={classes.action} onClick={onClick}>
      {children}
      {hasNewContent && <HeaderNewContentIndicator />}
    </li>
  );
};

export default HeaderAction;
