import GameIcon from 'components/GameIcon/GameIcon';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gamesActions, unselectedGamesSelector } from 'store/games';
import classes from './GameSelectorModal.module.scss';

interface Props {
  onSelect: () => void;
}

const GameSelectorModal: FC<Props> = ({ onSelect = () => null }) => {
  const games = useSelector(unselectedGamesSelector);
  const dispatch = useDispatch();

  const clickHandler = (gameId: string) => {
    dispatch(gamesActions.select(gameId));
    onSelect();
  };

  return (
    <div className={classes.container}>
      <ul className="unstyled-list">
        {games.map((game) => (
          <li key={game.id} className={classes.item} onClick={() => clickHandler(game.id)}>
            <GameIcon name={game.icon} className={classes.icon} size="small" />
            <span className={classes.name}>{game.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GameSelectorModal;
