import AnimatedCircle from 'components/UI/AnimatedCircle/AnimatedCircle';
import classes from './UnknownError.module.scss';

const UnknownError = () => {
  return (
    <>
      <AnimatedCircle top />
      <div className={classes.page}>
        <section className="container">
          <div className={classes.error}>
            <h1 className={classes.title}>¯\_(ツ)_/¯</h1>
            <h2 className={`mt4 ${classes.lead}`}>Oops! Unknown Error encountered.</h2>
            <div className="col-lg-8 offset-lg-2 col-12">
              <h3 className={classes.message}>
                The page you were looking for encountered an error and couldn't be displayed.
              </h3>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UnknownError;
