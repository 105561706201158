import { FC, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { unseenCountSelector } from 'store/notifications';
import { logout } from 'store/user';

import classes from './SideMenu.module.scss';

import ActionListItem from 'components/ActionListItem/ActionListItem';
import GamesWidget from 'components/GamesWidget/GamesWidget';
import { menuActions } from 'store/menu';
import useSlice from 'hooks/useSlice';
import { LanguageSelectorModal } from '@marsenit/gameout-ui/';

type Props = {
  className: string;
};

const SideMenu: FC<Props> = ({ className }) => {
  const [isLanguageSelectorVisible, setLanguageSelectorVisible] = useState(false);
  const unseenNotificationsCount = useSelector(unseenCountSelector);
  const { visible: isMenuVisible } = useSlice('menu');
  const dispatch = useDispatch();
  const mergedClasses = classNames(classes.menu, className);

  const tapHandler = () => {
    if (isMenuVisible) {
      dispatch(menuActions.toggle());
    }
  };

  const showLanguageSelector = () => setLanguageSelectorVisible(true);

  const closeLanguageSelector = () => setLanguageSelectorVisible(false);

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <aside className={mergedClasses}>
      <nav>
        <ul className={`unstyled-list ${classes.list}`}>
          <GamesWidget mobile />
          {unseenNotificationsCount > 0 && (
            <ActionListItem
              name="Notifications"
              href="/notifications"
              count={unseenNotificationsCount}
              postfixIcon="FaChartPie"
              className={classes.mobile}
              onClick={tapHandler}
            />
          )}
          <ActionListItem
            name="Dashboard"
            href="/dashboard"
            postfixIcon="FaChartPie"
            onClick={tapHandler}
          />
          <ActionListItem name="Games" href="/games" postfixIcon="FaGamepad" onClick={tapHandler} />
          <ActionListItem
            name="Billing"
            href="/billing"
            postfixIcon="FaCreditCard"
            onClick={tapHandler}
          />
          <ActionListItem
            name="Docs"
            href="/documentation"
            postfixIcon="FaCode"
            onClick={tapHandler}
          />
          <ActionListItem
            name="Change Language"
            postfixIcon="FaLanguage"
            className={classes.mobile}
            onClick={showLanguageSelector}
          />
          <ActionListItem
            name="Account Settings"
            href="/settings"
            postfixIcon="FaCog"
            className={classes.mobile}
            onClick={tapHandler}
          />
          <ActionListItem
            name="Log out"
            onClick={logoutHandler}
            postfixIcon="FaSignOutAlt"
            className={classes.mobile}
          />
        </ul>
      </nav>
      {isLanguageSelectorVisible && <LanguageSelectorModal onSelect={closeLanguageSelector} />}
    </aside>
  );
};

export default SideMenu;
