import { FC } from 'react';
import classes from './GameIcon.module.scss';
import classNames from 'classnames';
import Image from 'components/Image/Image';

export interface GameIconProps {
  name?: string;
  className?: string;
  label?: string;
  size?: string;
  loading?: boolean;
  highlightOnHover?: boolean;
  onClick?: () => void;
}

const GameIcon: FC<GameIconProps> = (props) => {
  const { className, size, label, highlightOnHover, onClick, ...rest } = props;

  const containerClasses = classNames(classes.container, className, {
    [classes.clickable]: onClick,
    [classes.small]: size === 'small',
    [classes.medium]: size === 'medium',
    [classes.big]: size === 'big',
    [classes['highlight-on-hover']]: highlightOnHover,
  });

  return (
    <div className={containerClasses} {...(onClick && { onClick })}>
      <Image {...rest} className={containerClasses} />
      {label && (
        <div className={classes.label}>
          <span className={classes['label-text']}>{label}</span>
        </div>
      )}
    </div>
  );
};

export default GameIcon;
