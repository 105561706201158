import { FC } from 'react';
import ActionListItem from 'components/ActionListItem/ActionListItem';
import classes from './AccountActionList.module.scss';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { logout } from 'store/user';

const AccountActionList: FC<{ className?: string }> = ({ className }) => {
  const dispatch = useDispatch();
  const componentClasses = classNames(classes.container, className);

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div className={componentClasses}>
      <ul className="unstyled-list">
        <ActionListItem name="Account Settings" href="/settings" prefixIcon="FaCog" />
        <ActionListItem name="Log out" onClick={logoutHandler} prefixIcon="FaSignOutAlt" />
      </ul>
    </div>
  );
};

export default AccountActionList;
