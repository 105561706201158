import { FC, ReactNode } from 'react';

import AnimatedCircle from 'components/UI/AnimatedCircle/AnimatedCircle';
import classes from './GuestLayout.module.scss';

import logoPng from 'assets/images/logo.png';

interface Props {
  children: ReactNode;
}

const GuestLayout: FC<Props> = ({ children }) => (
  <>
    <header className="container">
      <div className={classes.header}>
        <img className={classes['header__logo']} src={logoPng} alt="Gameout logo" />
        <p className={classes['header__nav']}>DEVELOPERS</p>
      </div>
    </header>
    <main className={classes['page-wrapper']}>
      <AnimatedCircle top />
      <section className={classes.page}>{children}</section>
    </main>
  </>
);

export default GuestLayout;
