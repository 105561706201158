import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  isAuthenticated: boolean;
  authToken?: string | null;
  email?: string | null;
}

const emptyState: UserState = {
  isAuthenticated: false,
};

const storedState = localStorage.getItem('user');
const initialState: UserState = storedState !== null ? JSON.parse(storedState) : emptyState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state, action: PayloadAction<string>) {
      state.isAuthenticated = true;
      state.authToken = action.payload;
    },
    logout() {
      return emptyState;
    },
    setAuthToken(state, action: PayloadAction<string>) {
      state.authToken = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
  },
});

export const { login, logout, setAuthToken, setEmail } = userSlice.actions;
export default userSlice.reducer;
