import { FC, ReactElement, ElementType, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';

interface Props {
  category?: string;
  name: string | undefined;
  className?: string;
  onClick?: () => void;
}

const categories = {
  fa: 'FontAwesome',
  gi: 'GameIcons',
};

const getCategory = (categoryId: string | undefined, name: string) => {
  let category = (
    categoryId ? categoryId : name.slice(0, 2)
  ).toLowerCase() as keyof typeof categories;
  return categories[category];
};

const Icon: FC<Props> = ({ category, name, className, onClick }) => {
  const mountedRef = useRef<boolean>(false);
  const [icon, setIcon] = useState<ReactElement | null>(null);

  useEffect(() => {
    mountedRef.current = true;
    if (!name) return;

    const iconClasses = classNames(className);
    import(`utils/icons/${getCategory(category, name)}`).then((module) => {
      if (mountedRef.current) {
        const IconElementTag: ElementType = module.default(name) || null;
        if (!IconElementTag) return;
        setIcon(<IconElementTag className={iconClasses} />);
      }
    });

    return () => {
      mountedRef.current = false;
    };
  }, [category, name, className]);

  if (onClick) {
    return <div onClick={onClick}>{icon}</div>;
  }
  return icon;
};

export default Icon;
