import { FC } from 'react';
import classes from './Image.module.scss';
import Icon from 'components/UI/Icon/Icon';
import { Spinner } from '@marsenit/gameout-ui';

interface Props {
  name?: string;
  className?: string;
  label?: string;
  alt?: string;
  loading?: boolean;
  onClick?: () => void;
}

const imageSrc = (name: string) => `https://media.gameout.app/${name}`;
// process.env.NODE_ENV === 'development'
//   ? `http://localhost:8080/media/${name}.jpg`
//   : `https://media.gameout.app/${name}`;

const Image: FC<Props> = ({ name, alt = 'Game image', loading }) => {
  if (!name) {
    return <Icon name="FaRegImage" />;
  }
  if (loading) {
    return <Spinner />;
  }
  return <img src={imageSrc(name)} className={classes.image} alt={alt} />;
};

export default Image;
