import { configureStore, PayloadAction } from '@reduxjs/toolkit';
import toast from './toast';
import lang from './lang';
import menu from './menu';
import user from './user';
import notifications from './notifications';
import games from './games';

const localStorageMiddleware = (store: any) => (next: any) => (action: PayloadAction) => {
  const result = next(action);
  const actionType = action.type.split('/')[0];
  const state = store.getState();

  switch (actionType) {
    case 'lang':
      localStorage.setItem('lang', state.lang.code);
      break;
    case 'user':
      localStorage.setItem('user', JSON.stringify(state.user));
      break;
    case 'games':
      localStorage.setItem('games', JSON.stringify(state.games));
      break;
    default:
      break;
  }

  return result;
};

const store = configureStore({
  reducer: {
    lang,
    toast,
    menu,
    user,
    notifications,
    games,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
