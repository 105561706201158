import { ElementType, FC } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/UI/Icon/Icon';
import classes from './ActionListItem.module.scss';
import classNames from 'classnames';
import GameIcon from 'components/GameIcon/GameIcon';

interface Props {
  name: string;
  href?: string;
  onClick?: () => void;
  gameIcon?: string;
  prefixIcon?: string;
  postfixIcon?: string;
  className?: string;
  count?: number;
}

const ActionListItem: FC<Props> = ({
  name,
  href,
  onClick = () => null,
  gameIcon,
  prefixIcon,
  postfixIcon,
  className,
  count = 0,
}) => {
  const Tag: ElementType = href ? Link : 'div';
  const elementClasses = classNames(classes['list-item'], className, {
    // [classes['list-item--game']]: gameIcon,
    [classes['list-item--right']]: postfixIcon,
  });
  const props = {
    className: elementClasses,
    onClick,
    ...(href ? { to: href } : {}),
  };

  return (
    <li>
      <Tag {...props}>
        {prefixIcon && <Icon name={prefixIcon} className={classes['icon-left']} />}
        {gameIcon && <GameIcon name={gameIcon} className={classes['game-icon']} />}
        <span className={`${classes.name} ${gameIcon ? classes['name--bold'] : ''}`}>{name}</span>
        {count > 0 && <span className={classes.count}>{count}</span>}
        {postfixIcon && <Icon name={postfixIcon} />}
      </Tag>
    </li>
  );
};

export default ActionListItem;
